import React from "react"
import img from "@components/pagePartners/images/intro.png"
import TitleUnderline from "./titleUnderline"

import { Link } from "gatsby"
const Intro = ({ data, location }) => {
  return (
    <section className="container-start-partners">
      <section className="container-start-partners-section">
        <img
          className="container-start-partners-logo"
          loading="lazy"
          src={data.logo}
          alt="Contact Center"
        />
        <p className="container-start-partners-title">
        <TitleUnderline underline="Sé un partner" /> de Beex        
        </p>
        <p className="container-start-partners-text">{data.textbody}</p>
        <Link
          className="container-start-partners-button"
          href={`/programa-partners-beex/formulario/${location.search}`}
        >
          Quiero ser un partner
        </Link>
      </section>
      <section className="container-start-partners-img-section">
        <img
          loading="lazy"
          className="container-start-partners-img"
          src={img}
          alt="animacion"
        />
      </section>
    </section>
  )
}

export default Intro
