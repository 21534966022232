import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg style={{bottom: "-2px"}} width="302" height="9" viewBox="0 0 302 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56.1465 9C49.9028 8.16452 4.18482 9.09727 0.86176 8.84548C-1.8512 8.50786 2.48435 2.5622 5.0026 1.68666C5.88529 1.37192 6.91076 1.32616 9.26026 1.46922C12.9857 1.73818 61.1571 1.20598 61.1571 1.20598C61.7931 1.38909 63.7013 0.77107 64.0518 0.994246C65.1551 1.51499 143.234 0.805402 149.893 0.77679C153.93 0.759622 150.737 1.11441 155.488 0.971352C165.613 0.685228 187.68 0.959909 195.351 0.633728C201.491 0.364771 202.815 0.353335 208.709 0.570789C214.732 0.765353 246.755 0.484949 250.416 0.0672084C251.311 -0.0472411 251.999 -0.00718033 251.999 0.124437C251.999 0.153049 274.573 0.250328 274.676 0.353332C274.845 0.484949 275.533 0.547892 276.169 0.456332C277.35 0.318993 301.378 -0.178864 301.754 0.204542C302.935 1.30326 299.625 5.98424 296.834 7.12301C293.979 8.3419 278.298 6.97424 265.694 7.70672L207.008 7.78111L194.43 8.2389C192.989 7.84405 187.446 8.83976 184.097 8.13017C182.682 7.84977 172.687 7.80971 170.48 8.10728C168.559 8.33618 117.428 7.75821 112.184 8.55363C102.838 7.88982 64.9344 8.83977 56.1465 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export default titleUnderline
