import React, { useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import TitleUnderline from "./titleUnderline"


export default function SyncSlider({ data }) {
  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()


  //   const [comilla, setComilla] = useState("rojo")
  const comillas = comilla => {
    if (comilla === "rojo") {
      return "container-whocan-partner-comillas-rojo"
    }
    if (comilla === "celeste") {
      return "container-whocan-partner-comillas-celeste"
    }
    if (comilla === "naranja") {
      return "container-whocan-partner-comillas-naranja"
    }
    if (comilla === "verde") {
      return "container-whocan-partner-comillas-verde"
    }
    if (comilla === "azul") {
      return "container-whocan-partner-comillas-azul"
    }
  }


  var settings = {
    slidesToShow: 4,
    infinite: true,
    initialSlide: 0,
    dots: false,
    speed: 500,
    responsive: [

      {
        breakpoint: 950,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          initialSlide: 0,
        },
      },
    ],
  }




  return (
    <section className="container-whocan-partner whocan-resposive">
      <p className="container-whocan-partner-title">
        ¿Quiénes pueden <TitleUnderline underline="participar?" />
      </p>

      <div className="container-whocan-partner-cards">
        <Slider
          asNavFor={nav1}
          ref={slider2 => setNav2(slider2)}
          {...settings}
        >
          {data.map(elemento => {
            return (
              <div
                class={`carta-box `}
                key={elemento.id}
              >
                <div class={`cara ${Detail(elemento)}`}>
                  <img src={elemento.img} className="card-icon" alt="..." />
                  <p className="container-whocan-partner-card-title">
                    {elemento.title}
                  </p>
                </div>
              </div>
            )
          })}
        </Slider>
        <div className="whocan-container-comillas-silder">
          <Slider asNavFor={nav2} ref={slider1 => setNav1(slider1)}>
            {data.map(elemento => {
              return (
                <div className="container-whocan-partner-card-text-description container-whocan-partner-card-text-description-desktop">
                  <p className="container-whocan-partner-card-text">
                    <span className={comillas(elemento.color)}>“</span>{" "}
                    {elemento.text}{" "}
                    <span className={comillas(elemento.color)}>”</span>{" "}
                  </p>
                </div>
              )
            })}
          </Slider>
        </div>
      </div>
    </section>
  )
}


const Detail = elemento => {
  if (elemento.color === "rojo") {
    return "container-whocan-partner-card-border-rojo"
  }
  if (elemento.color === "celeste") {
    return "container-whocan-partner-card-border-celeste"
  }
  if (elemento.color === "naranja") {
    return "container-whocan-partner-card-border-naranja"
  }
  if (elemento.color === "verde") {
    return "container-whocan-partner-card-border-verde"
  }
  if (elemento.color === "azul") {
    return "container-whocan-partner-card-border-azul"
  }
}

