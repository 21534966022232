import React, { useState } from "react"
import styled from "styled-components"
import TitleUnderline from "./titleUnderline"


//Rotacion flecha de Submenu
const IconButtonWrapper = styled.div`
  float: right;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  -webkit-transform: ${props =>
    props.rotate === "true" ? `rotate(90deg)` : ""};
`

//Boton Hamburguesa
const NavBar = () => {
  //Desplegar primera pregunta
  const [activateQuestionOne, setActivateQuestionOne] = useState(false)
  const [activateRequestOne, setActivateRequestOne] = useState(false)
  //
  const [QuestionTwo, setQuestionTwo] = useState(false)
  const [activateRequestTwo, setActivateRequestTwo] = useState(false)
  //
  const [QuestionThree, setQuestionThree] = useState(false)
  const [activateRequestThree, setActivateRequestThree] = useState(false)
  //
  const [QuestionFour, setQuestionFour] = useState(false)
  const [activateRequestFour, setActivateRequestFour] = useState(false)
  //
  const [QuestionFive, setQuestionFive] = useState(false)
  const [activateRequestFive, setActivateRequestFive] = useState(false)
  //
  const [QuestionSix, setQuestionSix] = useState(false)
  const [activateRequestSix, setActivateRequestSix] = useState(false)
  //
  const [QuestionSeven, setQuestionSeven] = useState(false)
  const [activateRequestSeven, setActivateRequestSeven] = useState(false)

  const clickQuestionOne = event => {
    event.preventDefault()
    setActivateQuestionOne(!activateQuestionOne)
    setActivateRequestOne(!activateRequestOne)
    //Cerrar submenuSoluciones
    setQuestionTwo(false)
    setActivateRequestTwo(false)
    setQuestionThree(false)
    setActivateRequestThree(false)
    setQuestionFour(false)
    setActivateRequestFour(false)
    setQuestionFive(false)
    setActivateRequestFive(false)
    setQuestionSix(false)
    setActivateRequestSix(false)
    setQuestionSeven(false)
    setActivateRequestSeven(false)
  }

  //Desplegar segunda pregunta
  const clickQuestionTwo = event => {
    event.preventDefault()
    setQuestionTwo(!QuestionTwo)
    setActivateRequestTwo(!activateRequestTwo)
    //Cerrar submenuProductos
    setActivateQuestionOne(false)
    setActivateRequestOne(false)
    setQuestionThree(false)
    setActivateRequestThree(false)
    setQuestionFour(false)
    setActivateRequestFour(false)
    setQuestionFive(false)
    setActivateRequestFive(false)
    setQuestionSix(false)
    setActivateRequestSix(false)
    setQuestionSeven(false)
    setActivateRequestSeven(false)
  }
  //Desplegar tercera pregunta
  const clickQuestionThree = event => {
    event.preventDefault()
    setQuestionThree(!QuestionThree)
    setActivateRequestThree(!activateRequestThree)
    //Cerrar submenuProductos
    setActivateQuestionOne(false)
    setActivateRequestOne(false)
    setQuestionTwo(false)
    setActivateRequestTwo(false)
    setQuestionFour(false)
    setActivateRequestFour(false)
    setQuestionFive(false)
    setActivateRequestFive(false)
    setQuestionSix(false)
    setActivateRequestSix(false)
    setQuestionSeven(false)
    setActivateRequestSeven(false)
  }
  //Desplegar cuarta pregunta
  const clickQuestionFour = event => {
    event.preventDefault()
    setQuestionFour(!QuestionFour)
    setActivateRequestFour(!activateRequestFour)
    //Cerrar submenuProductos
    setActivateQuestionOne(false)
    setActivateRequestOne(false)
    setQuestionTwo(false)
    setActivateRequestTwo(false)
    setQuestionThree(false)
    setActivateRequestThree(false)
    setQuestionFive(false)
    setActivateRequestFive(false)
    setQuestionSix(false)
    setActivateRequestSix(false)
    setQuestionSeven(false)
    setActivateRequestSeven(false)
  }
  //Desplegar quinta pregunta
  const clickQuestionFive = event => {
    event.preventDefault()
    setQuestionFive(!QuestionFive)
    setActivateRequestFive(!activateRequestFive)
    //Cerrar submenuProductos
    setActivateQuestionOne(false)
    setActivateRequestOne(false)
    setQuestionTwo(false)
    setActivateRequestTwo(false)
    setQuestionThree(false)
    setActivateRequestThree(false)
    setQuestionFour(false)
    setActivateRequestFour(false)
    setQuestionSix(false)
    setActivateRequestSix(false)
    setQuestionSeven(false)
    setActivateRequestSeven(false)
  }
  //Desplegar sexta pregunta
  const clickQuestionSix = event => {
    event.preventDefault()
    setQuestionSix(!QuestionSix)
    setActivateRequestSix(!activateRequestSix)
    //Cerrar submenuProductos
    setActivateQuestionOne(false)
    setActivateRequestOne(false)
    setQuestionTwo(false)
    setActivateRequestTwo(false)
    setQuestionThree(false)
    setActivateRequestThree(false)
    setQuestionFour(false)
    setActivateRequestFour(false)
    setQuestionFive(false)
    setActivateRequestFive(false)
    setQuestionSeven(false)
    setActivateRequestSeven(false)
  }
  //Desplegar siete pregunta
  const clickQuestionSeven = event => {
    event.preventDefault()
    setQuestionSeven(!QuestionSeven)
    setActivateRequestSeven(!activateRequestSeven)
    //Cerrar submenuProductos
    setActivateQuestionOne(false)
    setActivateRequestOne(false)
    setQuestionTwo(false)
    setActivateRequestTwo(false)
    setQuestionThree(false)
    setActivateRequestThree(false)
    setQuestionFour(false)
    setActivateRequestFour(false)
    setQuestionFive(false)
    setActivateRequestFive(false)
    setQuestionSix(false)
    setActivateRequestSix(false)
  }
  return (
    <section className="container-questions-partners">
      <p className="container-questions-partners-title">
        <TitleUnderline underline="Preguntas" /> Frecuentes
      </p>
      <ul className="container-questions-partners-list">
        <li className="container-questions-partners-list-li">
          <hr />
          <div
            className="container-questions-partners-list-liBtn"
            onClick={event => {
              clickQuestionOne(event)
            }}
          >
            ¿Cómo realmente ayuda el programa de partners a mi empresa?
            <div className="iconsubmenu">
              <IconButtonWrapper rotate={activateQuestionOne.toString()}>
                <IconMenu />
              </IconButtonWrapper>
            </div>
          </div>
          {activateRequestOne ? (
            <div className="container-questions-partners-list-request">
              Beex está realmente comprometido en mejorar las operaciones
              de todas las empresas, con productos omnicanales de largo alcance
              a precios justos, por lo que un partner asegura a su cliente una
              solución a todo nivel, además de comisionar bajo un esquema
              rentable.
            </div>
          ) : (
            false
          )}
        </li>
        <li className="container-questions-partners-list-li">
          <hr />
          <div
            className="container-questions-partners-list-liBtn"
            onClick={event => {
              clickQuestionTwo(event)
            }}
          >
            ¿Existe un monto mínimo de venta para empezar a comisionar?
            <div className="iconsubmenu">
              <IconButtonWrapper rotate={QuestionTwo.toString()}>
                <IconMenu />
              </IconButtonWrapper>
            </div>
          </div>

          {activateRequestTwo ? (
            <div className="container-questions-partners-list-request">
              No, dependiendo de las empresas que refieras o los negocios que
              cierres, empezarás a comisionar.{" "}
            </div>
          ) : (
            false
          )}
        </li>
        <li className="container-questions-partners-list-li">
          <hr />
          <div
            className="container-questions-partners-list-liBtn"
            onClick={event => {
              clickQuestionSix(event)
            }}
          >
            ¿Los costos de servicios de consultoría del partner, van totalmente al partner?
            <div className="iconsubmenu">
              <IconButtonWrapper rotate={QuestionSix.toString()}>
                <IconMenu />
              </IconButtonWrapper>
            </div>
          </div>

          {activateRequestSix ? (
            <div className="container-questions-partners-list-request">
              Sí, todos los servicios de consultoría especializada que el partner brinde a sus clientes, van 100% a ellos sin la intervención de Beex. Beex rentabiliza únicamente del sistema de licenciamiento.
            </div>
          ) : (
            false
          )}
        </li>
        <li className="container-questions-partners-list-li">
          <hr />
          <div
            className="container-questions-partners-list-liBtn"
            onClick={event => {
              clickQuestionThree(event)
            }}
          >
            ¿Cuándo se determina si un partner de ventas está totalmente
            capacitado para serlo?
            <div className="iconsubmenu">
              <IconButtonWrapper rotate={QuestionThree.toString()}>
                <IconMenu />
              </IconButtonWrapper>
            </div>
          </div>

          {activateRequestThree ? (
            <div className="container-questions-partners-list-request">
              Beex se encarga de la capacitación total del partner y
              determina cuando ya está listo para iniciar la comercialización de
              sus solucione.
            </div>
          ) : (
            false
          )}
        </li>
        <li className="container-questions-partners-list-li">
          <hr />
          <div
            className="container-questions-partners-list-liBtn"
            onClick={event => {
              clickQuestionFour(event)
            }}
          >
            ¿Con quién firma el contrato el cliente final de un partner de
            ventas?
            <div className="iconsubmenu">
              <IconButtonWrapper rotate={QuestionFour.toString()}>
                <IconMenu />
              </IconButtonWrapper>
            </div>
          </div>

          {activateRequestFour ? (
            <div className="container-questions-partners-list-request">
              Con el cliente final. Beex comparte un contrato macro al Partner para que lo use siempre en los negocios cerrados. También, el partner de ventas tiene un contrato exclusivo con Beex para transparentar todas las gestiones.
            </div>
          ) : (
            false
          )}
        </li>
        <li className="container-questions-partners-list-li">
          <hr />
          <div
            className="container-questions-partners-list-liBtn"
            onClick={event => {
              clickQuestionFive(event)
            }}
          >
            ¿Solo gano comisión por el tiempo de contrato firmado?
            <div className="iconsubmenu">
              <IconButtonWrapper rotate={QuestionFive.toString()}>
                <IconMenu />
              </IconButtonWrapper>
            </div>
          </div>

          {activateRequestFive ? (
            <div className="container-questions-partners-list-request">
              No, si la empresa referida o el negocio cerrado se queda más
              tiempo del que firmó contrato, la comisión sigue vigente hasta el
              final de la relación comercial con el cliente final.{" "}
            </div>
          ) : (
            false
          )}
        </li>

        <li className="container-questions-partners-list-li">
          <hr />
          <div
            className="container-questions-partners-list-liBtn"
            onClick={event => {
              clickQuestionSeven(event)
            }}
          >
            ¿Cuánto es el tiempo de implementación de los productos de
            Beex?
            <div className="iconsubmenu">
              <IconButtonWrapper rotate={QuestionSeven.toString()}>
                <IconMenu />
              </IconButtonWrapper>
            </div>
          </div>

          {activateRequestSeven ? (
            <div className="container-questions-partners-list-request">
              Depende. Para los planes Enterprise SaaS pueden ser
              automáticos, pero también pueden llevar un par de semanas si es
              que es necesario el canal de WhatsApp Business API, ya que es una
              solicitud. Para Beex, el tiempo promedio es de 20 a 30 días
              hábiles.
            </div>
          ) : (
            false
          )}
        </li>
      </ul>
    </section>
  )
}
export default NavBar

const IconMenu = () => {
  return (
    <svg
      width="9"
      height="18"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 11L6 6L1 1"
        stroke="#262A31"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
